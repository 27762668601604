import axios from "axios";
import { ACCESS_TOKEN, BASE_URL, CLIENT_REF } from "../config/app.config";

const API_URL = `${BASE_URL}users`;

// Get all users
export const getUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/get-all-users/${CLIENT_REF}`, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": ACCESS_TOKEN,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// user sign up
export const signUp = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}/sign-up/${CLIENT_REF}`,
      { user: data },
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const Login = async (email, password) => {
  try {

    const response = await axios.post(
      `${API_URL}/sign-in/${CLIENT_REF}`,
      {userCredentials:{ email, password}},
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const Logout = async (id) => {
  try {
    const clientIp = localStorage.getItem('clientIp');
    const response = await axios.post(
      `${API_URL}/update-user-personal-info/${CLIENT_REF}?userId=${id}`,
      {user: {
        numberOfDevices: 'minus',
        clientIp: clientIp
      }},
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}


export const editPassword = async (email, newPassword) => {
  try {
    const response = await axios.post(
      `${API_URL}/reset-password/${CLIENT_REF}?mode=in_app&isAdmin=true`,
      {resetData:{ email, newPassword}},
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    console.log(response)
    return response.data;
  } catch (error) {
    console.log(error);
  }
}



export const deleteUser = async (id, userStatus) => {
  try {
    const response = await axios.post(
      `${API_URL}/update-user-personal-info/${CLIENT_REF}?userId=${id}`,
      {user: {
        isActive: userStatus
      }},
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const upadteUserInfo = async (user, userId) => {
  try {
    await axios({
      method: `POST`,
      url: `${API_URL}/update-user-personal-info/${CLIENT_REF}?userId=${userId}`,
      headers: {
        'x-access-token': ACCESS_TOKEN
      },
      data: { user }
    });

    return;
  } catch (err) {
    throw new Error(
      `${err.response.statusText} - ${err.response.data.message}`
    );
  }
}

export const getUserBooks = async (id) => {
  
  try {
    const response = await axios.get(
      `${API_URL}/get-user-books/${CLIENT_REF}/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const addBook = async (bookCode) => {
  try {
    const response = await axios.post(
      `${API_URL}/add-user-book/${CLIENT_REF}/${localStorage.getItem('id')}`,
      {bookCode: bookCode},
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
