import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import * as bookServices from '../../services/book.services';
import './books.css';
import Header from '../../components/header';
import { Link } from "react-router-dom";
function BooksPage() {

    const [books, setBooks] = useState([]);
    const [newBooks, setNewBooks] = useState([]);

    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [addBookTitle, setAddBookTitle] = useState(null);
    const [addBookDescription, setAddBookDescription] = useState(null);
    const [addBookCodeBase, setAddBookCodeBase] = useState(null);
    const [addBookLanguage, setAddBookLanguage] = useState(null);
    const [addBookPublishYear, setAddBookPublishYear] = useState(null);
    const [addBookThumbnail, setAddBookThumbnail] = useState(null);
    const [bookThumbnailModal, setBookThumbnailModal] = useState(false);
    const [targetBook, setTargetBook] = useState(null);

    useEffect(() => {
        bookServices.getBooks().then((res) => {
            console.log(res)
            setBooks(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const handleDelete = (id, userStatus) => {
        bookServices.deleteBook(id, !userStatus).then((res) => {
            window.location.reload();
        }).catch((err) => {
            console.log(err);
        })
    }
    

    const handleSearch = (value) => {
      setNewBooks(books.filter((book) => book.title.includes(value)))
    }
    



    const handleCloseAddModal = () => {
        setAddModalOpen(false);
        setAddBookTitle(null);
        setAddBookDescription(null);
        setAddBookCodeBase(null);
        setAddBookLanguage(null);
        setAddBookPublishYear(null);
    }

    

    const handleSaveAddBook = () => {
        bookServices.addBook(addBookTitle, addBookDescription, addBookCodeBase, addBookLanguage, addBookPublishYear).then((res) => {
            setTargetBook(res.data._id);
            setAddModalOpen(false);
            setBookThumbnailModal(true);
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleSaveEdit = () => {
      bookServices.editBook(targetBook, addBookTitle, addBookDescription, addBookLanguage, undefined, [], addBookThumbnail).then((res) => {
          alert('Book added successfully');
          window.location.reload();
      }).catch((err) => {
          console.log(err);
      })
  }
  const handleCloseEditModal = () => {
    setBookThumbnailModal(false);
    setAddBookThumbnail(null);
    setTargetBook(null);
}
    const booksToMap = newBooks.length > 0 ? newBooks : books;


    const handleFileUpload = async (e) => {
      const maxFileSize = 5 * 1024 * 1024; // 5MB
      const fileData = e.target.files[0];
      const allowedExtensions = ['png', 'jpeg', 'jpg'];
      const fileExtension = fileData.name.split('.').pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        alert('Invalid file format. Please choose a PNG or JPEG file.');
        e.target.value = null; // Clear the input
        return;
      }
      if (fileData && fileData.size > maxFileSize) {
        // File size exceeds the limit, handle accordingly
        alert('File size exceeds the limit. Please choose a smaller file.');
        // Optionally, clear the input
        e.target.value = null;
      } else {
        const file = await bookServices.uploadFile(targetBook, e.target.files[0], 'image')
        setAddBookThumbnail(file.data)
      }

    }

return (
  <>
    <Header />
    <div className="admin-page">
      <div className='page-head'>
      <h1 className="header">Current Books</h1>
      <div className='book-actions'>
      <input type="text" className="search-input" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
      <Button className="add-button" onClick={() => setAddModalOpen(true)}>Add Book</Button>
      </div>
      </div>
      <TableContainer className="admin-table-container" component={Paper}>

        <Dialog
          open={addModalOpen}
          onClose={handleCloseAddModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Book</DialogTitle>
          <DialogContent>
          <label className="edit-book-label">Book Name</label>
            <input
              autoFocus
              type="text"
              placeholder="Book Title"
              value={addBookTitle}
              onChange={(e) => setAddBookTitle(e.target.value)}
              className="edit-book-name"
            />
            <label className="edit-book-label">Book Code Base</label>
            <input
              autoFocus
              type="text"
              placeholder="Book Code Base"
              value={addBookCodeBase}
              onChange={(e) => setAddBookCodeBase(e.target.value)}
              className="edit-book-name"
            />
            <label className="edit-book-label">Book Description</label>
            <textarea
              type="text"
              placeholder="Description"
              value={addBookDescription}
              onChange={(e) => setAddBookDescription(e.target.value)}
              className="edit-book-description"
            />
<label className="edit-book-label">Book Language</label>
            <select
  type="text"
  placeholder="Language"
  value={addBookLanguage}
  onChange={(e) => setAddBookLanguage(e.target.value)}
  className="edit-book-name"
>
  {['English', 'Turkish', 'Arabic', 'German', 'French'].map((language, index) => (
    <option key={index} value={language}>
      {language}
    </option>
  ))}
</select>
<label className="edit-book-label">Book Publish Year</label>
            <input
              autoFocus
              type="text"
              placeholder="Book Publish Year"
              value={addBookPublishYear}
              onChange={(e) => setAddBookPublishYear(e.target.value)}
              className="edit-book-name"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveAddBook} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={bookThumbnailModal}
          onClose={handleCloseEditModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Book</DialogTitle>
          <DialogContent>
          <label className="voice-file-label">Book Thumbnail</label>
            <input type="file" name='image' className='voice-file-uploader' onChange={(e) => handleFileUpload(e)}/>
            <p className='instructions'>File size should not exceed 5 MB, also this form only accepts PNG/JPEG/JPG files</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveEdit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Table className="admin-table">
          <TableHead>
            <TableRow className="table-header-row">
              <TableCell className="table-cell">Name</TableCell>
              <TableCell className="table-cell">Description</TableCell>
              <TableCell className="table-cell">Code base</TableCell>
              <TableCell className="table-cell">Sample</TableCell>
              <TableCell className="table-cell">Status</TableCell>
              <TableCell className="table-cell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {booksToMap.map((book) => (
              <TableRow key={book._id} className="table-row">
                <TableCell width="15%" className="table-cell">
                <Link to={`/bookDetails/${book._id}`}> {book.title} </Link>
                </TableCell>
                <TableCell width="60%" className="table-cell">
                  {book.description}
                </TableCell>
                <TableCell width="5%" className="table-cell">
                  {book.codeBase}
                </TableCell>
                <TableCell width="5%" className="table-cell">
                  {book.sample}
                </TableCell>
                <TableCell width="5%" className="table-cell">
                  {book.isActive ? "active" : "inactive"}
                </TableCell>
                <TableCell width="15%" className="table-cell">
                  <Button
                    className="delete-button"
                    onClick={() => handleDelete(book._id, book.isActive)}
                  >
                    {book.isActive ? "Decativate book" : "Activate book"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  </>
);
}
export default BooksPage;