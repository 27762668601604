import React, { useState, useEffect } from "react";
import "./bookCodes.css"; // Import your CSS file
import * as bookServices from "../../services/book.services";
import { useParams } from "react-router-dom";
import { ACCESS_TOKEN, BASE_URL, CLIENT_REF } from "../../config/app.config";
import Header from "../../components/header";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import readXlsxFile from "read-excel-file";
import moment from "moment";

const BookCodes = () => {
  const [bookCodes, setBookCodes] = useState();
  const codeBase = useParams().codeBase;
  const bookId = useParams().bookId;
  const [importedData, setImportedData] = useState([]);
  const [codesModalOpen, setCodesModalOpen] = useState(false);
  const [baseId, setBaseId] = useState(null);
  const [duplicatedCodes, setDuplicatedCodes] = useState([]);
  const [users, setUsers] = useState([]);
  const [newBookCodes, setNewBookCodes] = useState([]);
  const handleSaveBookCodes = () => {
    if(bookCodes.length < 0) {
    bookServices.inserBookCodes(codeBase, importedData).then((res) => {
      alert('Codes added successfully');
      setCodesModalOpen(false);
      setImportedData([]);    
    }
    ).catch((err) => {
      console.log(err);
    })}
    else{
    bookServices.addBookCode(baseId, importedData).then((res) => {
      if(duplicatedCodes.length > 0){
      alert(`Done with some duplicated codes: ${duplicatedCodes}`)
      setCodesModalOpen(false);
      setImportedData([]);
      window.location.reload();
      }  
      else{
      alert('Codes added successfully');
      setCodesModalOpen(false);
      setImportedData([]);
      window.location.reload();
        }
  }).catch((err) => {
      console.log(err);
  })}
  }
  useEffect(() => {
    bookServices
      .getBookByCode(codeBase)
      .then((res) => {
        console.log(res)
        setBaseId(res.data.result._id);
        setBookCodes(res.data.result.codes);
        setUsers(res.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSearch = (value) => {
    setNewBookCodes(bookCodes.filter((code) => code.code.includes(value)))
  }
  return (
    <div>
      <Header />
      {bookCodes ? (
        <>
        <div className="admin-page">
        <div className='page-head'>
          <h1 className="header">Book Codes</h1>
          <div className="book-actions-codes">
          <input type="text" className="search-input" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />

          <Button className="edit-book-btn" onClick={() => setCodesModalOpen(true)}>Add Book Codes</Button>
          <Button className="back-books-btn"><Link to={`/bookDetails/${bookId}`}>Back to book details</Link></Button>
          </div>
            </div>
          <Dialog open={codesModalOpen} onClose={() => setCodesModalOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Book Codes</DialogTitle>
        <DialogContent>
        <label className="voice-file-label">Codes file</label>
            <input type="file" name='codes' className='voice-file-uploader' onChange={(e) => {
              readXlsxFile(e.target.files[0]).then((rows) => {
                for(let i = 0; i < rows.length; i++) {
                  let row = {
                    code: rows[i][0],
                    isUsed: false
                  }
                  if(!bookCodes.find((code) => code.code === row.code)) {
                    setImportedData(current => [...current, row])
                  }
                  else{
                    setDuplicatedCodes(current => [...current, row.code])
                    console.log('Code already exists')
                  }
                }
              })
            }}/>
            <div className="instructions">
                <p>Instructions:</p>
                <p>1. Please upload a .xlsx file</p>
                <p>2. The file should contain only one column</p>
                <p>3. The column should contain the codes</p>
                <p>4. The codes should be in the first column</p>
                <p>5. The codes should look like this RS-AA01-XXXX-XXXX</p>
            </div>
            <p>And here's an <a href="https://redsea-books-assets.s3.eu-central-1.amazonaws.com/codes-example.xlsx">Example</a> of the book codes file</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setCodesModalOpen(false); setImportedData([])}} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveBookCodes} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Code</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Used By</TableCell>
                  <TableCell>Activated At</TableCell>
                  <TableCell>Active Until</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {newBookCodes?.length > 0 ? (
  newBookCodes.map((bookCode) => (
    <TableRow key={bookCode._id}>
      <TableCell component="th" scope="row">
        {bookCode.code}
      </TableCell>
      <TableCell>
        {bookCode.isUsed ? "Code is used" : "Code is available"}
      </TableCell>
      <TableCell>
        {users.find((user) => user.codeId === bookCode._id)?.userName || "Not used yet"}
      </TableCell>
      <TableCell>
        {bookCode.activeOn ? moment(bookCode.activeOn).format('YYYY-MM-DD') : "Not used yet"}
      </TableCell>
      <TableCell>
        {bookCode.activeUntil ? moment(bookCode.activeUntil).format('YYYY-MM-DD') : "Not used yet"}
      </TableCell>
    </TableRow>
  ))
) : (
  bookCodes.map((bookCode) => (
    <TableRow key={bookCode._id}>
      <TableCell component="th" scope="row">
        {bookCode.code}
      </TableCell>
      <TableCell>
        {bookCode.isUsed ? "Code is used" : "Code is available"}
      </TableCell>
      <TableCell>
        {users.find((user) => user.codeId === bookCode._id)?.userName || "Not used yet"}
      </TableCell>
      <TableCell>
        {bookCode.activeOn ? moment(bookCode.activeOn).format('YYYY-MM-DD') : "Not used yet"}
      </TableCell>
      <TableCell>
        {bookCode.activeUntil ? moment(bookCode.activeUntil).format('YYYY-MM-DD') : "Not used yet"}
      </TableCell>
    </TableRow>
  ))
)}

              </TableBody>
            </Table>
          </TableContainer>
          </div>
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </div>
  );
};

export default BookCodes;
