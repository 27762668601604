import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import * as contactsService from '../../services/support.services';
import './supportTicketDetails.css';
import Header from '../../components/header';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

function SupportTicketDetails() {

    const [contact, setContact] = useState();
    const ticketId = useParams().ticketId;
    useEffect(() => {
        contactsService.getSingleContact(ticketId).then((res) => {
            setContact(res.data);
        });
    }, []);

    return (
        <div>
            <Header />
            <div className='contact-details'>
                <h1>Ticket Title: {contact?.title}</h1>
                <h3>Full Name: {contact?.name}</h3>
                <h3>Email: {contact?.email}</h3>
                <p>Message: {contact?.message}</p>
                Contact Image: <img src={contact?.image} alt="contact" />
            </div>
        </div>
    )
}

export default SupportTicketDetails;