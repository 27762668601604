import axios from "axios";
import { ACCESS_TOKEN, BASE_URL, CLIENT_REF } from "../config/app.config";

const API_URL = `${BASE_URL}contact`;

export const getAllContacts = async () => {
    try {
        const response = await axios.get(`${API_URL}/get-all-contacts/${CLIENT_REF}`, {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": ACCESS_TOKEN,
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const getSingleContact = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/get-single-contact/${id}/${CLIENT_REF}`, {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": ACCESS_TOKEN,
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}