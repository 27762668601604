import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import * as contactsService from '../../services/support.services';
import './supportTickets.css';
import Header from '../../components/header';
import { Link } from "react-router-dom";

function SupportTicketsPage() {

    const [contacts, setContacts] = useState();
    const [newContacts, setNewContacts] = useState([]);

    useEffect(() => {
        contactsService.getAllContacts().then((res) => {
            setContacts(res.data);
        });
    }, []);

    const handleSearch = (value) => {
        setNewContacts(contacts.filter((contact) => contact.email.includes(value) 
        || contact.name.includes(value) 
        || contact.title.includes(value) 
        || contact.message.includes(value)));
      }
      const contactsToMap = newContacts.length > 0 ? newContacts : contacts;

    console.log(contacts);

    return (
        <div>
            <Header />
            <div  className='contact-table'>
                <h1>Support Tickets</h1>
                <input type="text" className="search-input" id="search-input-contacts" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Full Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contactsToMap?.map((contact) => (
                                <TableRow key={contact._id}>
                                    <TableCell>{contact.name}</TableCell>
                                    <TableCell>{contact.email}</TableCell>
                                    <TableCell>{contact.title}</TableCell>
                                    <TableCell>{contact.message}</TableCell>
                                    <TableCell>
                                        <Link to={`/support/${contact._id}`}>
                                            <Button variant="contained" color="primary">View</Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )

}
export default SupportTicketsPage;