import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import * as userService from '../../services/user.services';
import './users.css';
import Header from '../../components/header'
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function UsersPage() {
    const [users, setUsers] = useState([]);
    const [editUserId, setEditUserId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [editedUserName, setEditedUserName] = useState('');
    const [editUserEmail, setEditUserEmail] = useState('');
    const [editUserPhoto, setEditUserPhoto] = useState('');
    const [editUserPhone, setEditUserPhone] = useState('');
    const [editUserSchool, setEditUserSchool] = useState('');
    const [editUserRole, setEditUserRole] = useState('');
    const [currentUserRole, setCurrentUserRole] = useState('');

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [userRole, setUserRole] = useState("");
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [editPasswordModal, setEditPasswordModal] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [resetEmail, setResetEmail] = useState('');

    const [newUsers, setNewUsers] = useState([]);

    useEffect(() => {
        // Fetch users data from an API or database
        // Replace this with your own logic to fetch the users
        const fetchUsers = async () => {
            try {
                const response = await userService.getUsers();
                // console.log(response);
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);
    const handleEdit = (user) => {
        setEditedUserName(user.name);
        setEditUserEmail(user.email);
        setEditUserId(user._id);
        setEditUserPhone(user?.phone);
        setEditUserSchool(user?.schoolName);
        setCurrentUserRole(user.role);
        setEditUserRole(user.role);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setEditUserId(null);
        setEditedUserName(null);
        setEditUserEmail(null);
        setEditUserPhone(null);
        setEditUserSchool(null);
        setEditUserRole(null);
        setCurrentUserRole(null);
    };
    
    const handleClosePasswordModal = () => {
        setEditPasswordModal(false);
        setResetEmail('');
        setNewPassword('');
    };

    const handleOpenPasswordModal = (user) => {
        console.log(user);
        setEditPasswordModal(true);
        setResetEmail(user)
    };

    const editPassword = () => {
        userService.editPassword(resetEmail, newPassword).then((res) => {
            alert('Password changed successfully');
            console.log(res);
            setEditPasswordModal(false);
            setResetEmail('');
            setNewPassword('');
            setTimeout(() => {
            window.location.reload();
            }, 100);
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleCloseCreateModal = () => {
        setOpenCreateModal(false);
    };

    const handleSaveEdit = () => {
        userService.upadteUserInfo({ name: editedUserName}, editUserId).then((res) => {
            alert('User updated successfully');
            setOpenModal(false);
            setEditUserId(null);
            setTimeout(() => {
            window.location.reload();
            }, 100);
        }
        ).catch((err) => {
            console.log(err);
        })

    };
    const handleDelete = (id, userStatus) => {
        userService.deleteUser(id, !userStatus).then((res) => {
            window.location.reload();
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
      };
    
      const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        const passwordPattern =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*]).{8,}$/; // Password pattern: Minimum eight characters, at least one letter, and one number
        if (!passwordPattern.test(newPassword)) {
          setPasswordError(
            "The password must be at least 8 characters long and contain upper and lower case letters, numbers and special characters"
          );
        } else {
          setPasswordError("");
        }
      };
      
    
      const handleSetRole = (e) => {
        setUserRole(e.target.value);
      };
    
      const handleConfirmedPasswordChange = (e) => {
        const newConfirmedPassword = e.target.value;
        setConfirmedPassword(newConfirmedPassword);
        if (newConfirmedPassword !== password) {
          setPasswordMismatch(true);
        } else {
          setPasswordMismatch(false);
        }
      };
    
      const toggleShowPassword = () => {
        setShowPassword(!showPassword);
      };
    
      const handleSetFullName = (e) => {
        setFullName(e.target.value);
      };
    
      const handleSetPhoneNumber = (e) => {
        setPhoneNumber(e.target.value);
      };
    
      const handleSetSchoolName = (e) => {
        setSchoolName(e.target.value);
      };
    
      const handleSignup = (e) => {
        e.preventDefault();
        if (passwordError) {
          return;
        }
        if (password !== confirmedPassword) {
          setPasswordMismatch(true);
          return;
        } else {
          const user = {
            email,
            password,
            role: userRole ? userRole : "STUDENT",
            name: fullName,
            schoolName: userRole == "TEACHER" ? schoolName : undefined,
            phone: userRole == "TEACHER" ? phoneNumber : undefined,
          };
          userService
            .signUp(user)
            .then((res) => {
              if (res.error) {
                alert(res.details)
                return;
              } else {
                alert("User Created Successfully")
                setTimeout(() => {
                  window.location.reload();
                }, 100);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      };

      const handleSearch = (value) => {
        setNewUsers(users.filter((user) => user.email.includes(value) || user.name.includes(value)))
      }
      const usersToMap = newUsers.length > 0 ? newUsers : users;
    

    return (
        <>
        <Header />
        <div className="admin-page">
          <div className='page-head'>
        <h1 className="header">Current Users</h1>
        <div className='book-actions'>
        <input type="text" className="search-input" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
        <Button className='create-user-button' onClick={() => setOpenCreateModal(true)}>Create user</Button>
        </div>
        </div>
        <TableContainer className="admin-table-container" component={Paper}>
        <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
                    <DialogContent>
                        <input
                            autoFocus
                            type="text"
                            placeholder="Full Name"
                            value={editedUserName}
                            onChange={(e) => setEditedUserName(e.target.value)}
                            className='edit-user-name'
                        />
                        <input
                            type="email"
                            placeholder='Email'
                            value={editUserEmail}
                            onChange={(e) => setEditUserEmail(e.target.value)}
                            className='edit-user-email'
                        />
                        {currentUserRole === 'TEACHER' && (
                            <>
                            <input
                            type="number"
                            placeholder='Phone Number'
                            value={editUserPhone}
                            onChange={(e) => setEditUserPhone(e.target.value)}
                            className='edit-user-name'/>
                            <input
                            type="text"
                            placeholder='School'
                            value={editUserSchool}
                            onChange={(e) => setEditUserSchool(e.target.value)}
                            className='edit-user-name'/>
                            </>
                        )}
                        <select className='edit-user-name' value={editUserRole} onChange={(e) => setEditUserRole(e.target.value)}>
                            <option value='STUDENT'>Student</option>
                            <option value='TEACHER'>Teacher</option>
                        </select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSaveEdit} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openCreateModal} onClose={handleCloseCreateModal} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
                    <DialogContent className='singup-content'>
                    <form className="signup-form" onSubmit={handleSignup}>
        <input
          type="text"
          placeholder="Full Name"
          value={fullName}
          onChange={handleSetFullName}
          required={true}
          className="signup-field"
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
          required={true}
          className="signup-field"
        />
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
          required={true}
          className="signup-field"
        />
        {!passwordError && (
        <p className="password-message">
          The password must be at least 8 characters long and contain upper and
          lower case letters, numbers and special characters
        </p>)}
        {passwordError && <p className="password-error">{passwordError}</p>}
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Confirm Password"
          value={confirmedPassword}
          onChange={handleConfirmedPasswordChange}
          required={true}
          className="signup-field"
        />
        {passwordMismatch && (
          <p className="password-mismatch-error">Passwords do not match!</p>
        )}
        <button
          type="button"
          className="password-visibilty-button"
          onClick={toggleShowPassword}
        >
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </button>

        <select
          className="signup-field"
          value={userRole}
          onChange={handleSetRole}
          required={true}
        >
          <option value="STUDENT">Student</option>
          <option value="TEACHER">Teacher</option>
        </select>
        {userRole === "TEACHER" && (
          <>
            <input
              type="number"
              placeholder="Phone Number"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              value={phoneNumber}
              onChange={handleSetPhoneNumber}
              required="required"
              className="signup-field"
            />
            <input
              type="text"
              value={schoolName}
              onChange={handleSetSchoolName}
              placeholder="School"
              required={true}
              className="signup-field"
            />
          </>
        )}
        {/* <button className="submit-form-button" type="submit">
          Sign Up
        </button> */}
      </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseCreateModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSignup} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={editPasswordModal} onClose={handleClosePasswordModal} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
                    <DialogContent>
                        <input
                        type={"password"}
                        placeholder="Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required={true}
                        className="signup-field"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePasswordModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={editPassword} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            <Table className="admin-table">
                <TableHead>
                    <TableRow className="table-header-row">
                        <TableCell className="table-cell">Name</TableCell>
                        <TableCell className="table-cell">Email</TableCell>
                        <TableCell className="table-cell">Role</TableCell>
                        <TableCell className="table-cell">Phone</TableCell>
                        <TableCell className='table-cell'>School</TableCell>
                        <TableCell className="table-cell">Status</TableCell>
                        <TableCell className="table-cell">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usersToMap.map((user) => (
                        <TableRow key={user.id} className="table-row">
                            <TableCell className="table-cell">{user.name}</TableCell>
                            <TableCell className="table-cell">{user.email}</TableCell>
                            <TableCell className="table-cell">{user.role}</TableCell>
                            <TableCell className="table-cell">{user?.phone}</TableCell>
                            <TableCell className="table-cell">{user?.schoolName}</TableCell>
                            <TableCell className="table-cell">{user.isActive ? 'active' : 'inactive'}</TableCell>
                            <TableCell className="table-cell">
                                <Button className="edit-button" onClick={() => handleEdit(user)}>Edit</Button>
                                <Button className="delete-button" onClick={() => handleDelete(user._id, user.isActive)}>{user.isActive ? 'Decativate user' : 'Activate user'}</Button>
                                <Button className="delete-button" onClick={() => handleOpenPasswordModal(user.email)}>Change password</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
        </>
    );
    
}

export default UsersPage;
