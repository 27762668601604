import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import redsea from "../images/redsea.png";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import "./header.css";
import { Link } from "react-router-dom";
import * as userServices from "../services/user.services";
import GroupIcon from '@mui/icons-material/Group';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LogoutIcon from '@mui/icons-material/Logout';

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    userServices.Logout(localStorage.getItem('id')).then((res) => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('id');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    localStorage.removeItem('avatar');
    localStorage.removeItem('accessedBooks');
    localStorage.removeItem('clientIp')
    window.location.href = '/';}).catch((err) => {
      console.log(err)
    })
  }

  return (
    <AppBar
      className="app-header-box"
      position="sticky"
      style={{ zIndex: 999 }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {localStorage.getItem("isAuthenticated") != "true" && (
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {/* {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))} */}
                <Link className="login-signup-mobile" to="/login">
                  <Button sx={{ my: 2, color: "black", display: "flex" }}>
                    <Typography textAlign="center">Login</Typography>
                  </Button>
                </Link>
                {/* <Link className="login-signup-mobile" to="/signup">
                <Button sx={{ my: 2, color: "black", display: "flex" }}>
                <Typography textAlign="center">Sign Up</Typography>
                </Button></Link> */}
              </Menu>
            </Box>
          )}
          {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box> */}

          {localStorage.getItem("isAuthenticated") == "true" ? (
            <Box sx={{ flexGrow: 0 }} className="avatar-box">
              {/* <Link className="myLibraryLink" to="/userBooks">My Library</Link> */}

              <Tooltip title="Users" placement="bottom">
              <Link className="link-dropdown" to="/users">
                <GroupIcon />
              </Link>
              </Tooltip>
              <Tooltip title="Books" placement="bottom">
              <Link className="link-dropdown" to="/books">
                <LibraryBooksIcon />
              </Link>
              </Tooltip>
              <Tooltip title="Support Tickets" placement="bottom">
              <Link className="link-dropdown" to="/support-tickets">
                <SupportAgentIcon />
              </Link>
              </Tooltip>
              <Tooltip title="Logout" placement="bottom">
              <LogoutIcon className="link-dropdown" onClick={handleLogout} />
              </Tooltip>

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem>
                  <Link className="link-dropdown" to="/users">
                    <Typography textAlign="center">Users</Typography>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link className="link-dropdown" to="/books">
                    <Typography textAlign="center">Books</Typography>
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center" onClick={handleLogout}>
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <>
              <Box
                sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                className="login-box"
              >
                <Link className="login-signup" to="/login">
                  <Button sx={{ my: 2, color: "white", display: "block" }}>
                    Login
                  </Button>
                </Link>
                <Link className="login-signup" to="/signup">
                  <Button sx={{ my: 2, color: "white", display: "block" }}>
                    Sign Up
                  </Button>
                </Link>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
