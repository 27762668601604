import axios from "axios";
import { ACCESS_TOKEN, BASE_URL, CLIENT_REF } from "../config/app.config";

const API_URL = `${BASE_URL}books`;

export const getBookById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/get-book-by-id/${CLIENT_REF}/${id}`, {
        headers: {
            "Content-Type": "application/json",
            "x-access-token": ACCESS_TOKEN,
        },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const getBooks = async () => {
    try {
        const response = await axios.get(`${API_URL}/get-all-books/${CLIENT_REF}?isAdmin=true`, {
        headers: {
            "Content-Type": "application/json",
            "x-access-token": ACCESS_TOKEN,
        },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const deleteBook = async (id, status) => {
    try {
        const response = await axios.put(`${API_URL}/update-book-by-id/${CLIENT_REF}/${id}`, 
        
        {book:{isActive: status}}, {
        headers: {
            "Content-Type": "application/json",
            "x-access-token": ACCESS_TOKEN,
        },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const editBook = async (id, title, description, language, PDFFile, pages, thumbnail, digitalEdition, digitalEditionPageNumber) => {
    try {
        console.log(ACCESS_TOKEN, 'ACCESS_TOKEN')
        console.log(PDFFile, 'PDFFile')
        
        const response = await axios.put(`${API_URL}/update-book-by-id/${CLIENT_REF}/${id}`, 
        {book:{title: title, description: description, language: language ? language : 'English', sample: PDFFile ? PDFFile : null, pages: pages, thumbnail: thumbnail, digitalEdition: digitalEdition, digitalEditionPageNumber: digitalEditionPageNumber}}, {
        headers: {
            "Content-Type": "application/json",
            "x-access-token": ACCESS_TOKEN,
        },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const uploadFile = async (id, file, type) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(`${BASE_URL}upload/image/${CLIENT_REF}/${id}/${type}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "x-access-token": ACCESS_TOKEN,
        },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const addPage = async (id, pageNumber, voiceFile, videoFile, action) => {
  try {
    let body;
    console.log(action);
    if (action === "updateMediaName"){
        body =  {
            book: {
              pages: [
                {
                  number: pageNumber,
                  voiceFiles: voiceFile,
                  videoFiles: videoFile,
                },
              ],
            },
          };
    }
    else{
        body = {
          book: {
            pages: [
              {
                number: pageNumber,
                voiceFile: voiceFile,
                videoFile: videoFile,
              },
            ],
          },
        };
    }
    const response = await axios.put(
      `${API_URL}/update-book-by-id/${CLIENT_REF}/${id}?action=${action}`,

        body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": ACCESS_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addBook = async (title, description, codeBase, language, publishYear) => {
    try {
        const response = await axios.post(`${API_URL}/insert-book/${CLIENT_REF}`, 
        
        {book:{title: title, description: description, codeBase: codeBase, language: language ? language : 'English', publishYear: publishYear}}, {
        headers: {
            "Content-Type": "application/json",
            "x-access-token": ACCESS_TOKEN,
        },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const getBookByCode = async (code) => {
    try {
        const response = await axios.get(`${BASE_URL}book-codes/get-all-book-codes/${CLIENT_REF}/${code}`, {
        headers: {
            "Content-Type": "application/json",
            "x-access-token": ACCESS_TOKEN,
        },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const addBookCode = async (baseId,code) => {
    try {
        const response = await axios.put(`${BASE_URL}/book-codes/append-to-code-base-by-id/${CLIENT_REF}?baseId=${baseId}`, 
        
        {codes:code}, {
        headers: {
            "Content-Type": "application/json",
            "x-access-token": ACCESS_TOKEN,
        },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const inserBookCodes = async (codeBase,code) => {
    try {
        const response = await axios.post(`${BASE_URL}/book-codes/insert-book-codes/${CLIENT_REF}`, 
        
        {book:{
            "codeBase": codeBase,
            "codes": code
        }}, {
        headers: {
            "Content-Type": "application/json",
            "x-access-token": ACCESS_TOKEN,
        },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const deleteBookPage = async (id, pageId) => {
    try {
        const response = await axios.put(`${API_URL}/delete-book-page-by-id/${CLIENT_REF}/${id}/${pageId}`, 
        
        {}, {
        headers: {
            "Content-Type": "application/json",
            "x-access-token": ACCESS_TOKEN,
        },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}