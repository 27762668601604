import './App.css';
import Login from './pages/login/login';
// import Signup from './pages/signup/signup';
import Home from './pages/home/home';
import UsersPage from './pages/users/users';
import BooksPage from './pages/books/books';
import BookCodes from './pages/bookCodes/bookCodes';
// import UserBooks from './pages/userBooks/userBooks';
import BookDetails from './pages/bookDetails/bookDetails';
import SupportTicketsPage from './pages/supportTickets/supportTickets';
import SupportTicketDetails from './pages/supportTicketDetails/supportTicketDetails';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Switch>


        {/* <Route path="/about" component={AboutMe} exact/> */}
        {localStorage.getItem('isAuthenticated') ? <Route path="/" component={BooksPage} exact/> : <Route path="/" component={Login} exact/>}
        {localStorage.getItem('isAuthenticated') ? <Route path="/users" component={UsersPage} exact/> : <Route path="/users" component={Login} exact/>}
        {localStorage.getItem('isAuthenticated') ? <Route path="/books" component={BooksPage} exact/> : <Route path="/books" component={Login} exact/>}
        {localStorage.getItem('isAuthenticated') ? <Route path="/bookDetails/:bookId" component={BookDetails} exact/> : <Route path="/bookDetails/:bookId" component={Login} exact/>}
        {localStorage.getItem('isAuthenticated') ? <Route path="/bookDetails/:bookId/:codeBase" component={BookCodes} exact/> : <Route path="/bookDetails/:bookId/:codeBase" component={Login} exact/>}
        {localStorage.getItem('isAuthenticated') ? <Route path="/login" component={BooksPage} exact/> : <Route path="/login" component={Login} exact/>}
        {localStorage.getItem('isAuthenticated') ? <Route path="/support-tickets" component={SupportTicketsPage} exact/> : <Route path="/support-tickets" component={Login} exact/>}
        {localStorage.getItem('isAuthenticated') ? <Route path="/support/:ticketId" component={SupportTicketDetails} exact/> : <Route path="/support/:ticketId" component={Login} exact/>}
        {/* <Route path="/signup" component={Signup} exact/> */}
        {/* <Route path="/userBooks" component={UserBooks} exact/> */}
      </Switch>
    </BrowserRouter>
  );
}


export default App;
